.mc-page {
  position: relative;

  @media (max-width: 992px) {
    padding-top: 40px;

  }

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 100px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }

    p {
      font-family: $ZonaPro-Thin;
      color: var(--negro-semitico, #000);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: justify;

      a {
        color: var(--negro-semitico, #000);
        line-break: anywhere;

        &:hover {
          font-weight: bold !important;
        }
      }
    }

  }

  &.sectionLogin {
    padding: 151px 0 97px 0;
    background: rgba(0, 36, 75, 0.75);
    background-image: url('../../../public/assets/images/bg-car-login.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (min-width: 1366px) {
      padding: 165px 0 100px 0;
      background-position: center 60%;
    }


    @media(max-width: 992px) {
      padding: 109px 0 80px 0;
      background-image: url('../../../public/assets/images/bg-car-login-mobile.png');
      background-position: top center;
    }

    .wrap-content-login {
      // padding: 25px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h1 {
        margin-bottom: 30px;

        @media (max-width: 992px) {
          margin-bottom: 20px;
          padding-top: 20px;

        }
      }

      .imgTitle {
        padding-top: 25px;
        margin-bottom: 30px;
        position: relative;

        // overflow: hidden;
        &::after {
          content: '';
          height: 2px;
          width: 100%;
          position: absolute;
          top: 0px;
          left: -50px;
          background-color: #ffffffc1;
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
          animation: line 2s linear;
          -webkit-animation: line 2s linear;
          animation-fill-mode: forwards;

          @media (max-width: 992px) {
            left: -21px;
          }

          @media (max-width: 576px) {
            left: -22px;
          }
        }

        &::before {
          content: url('../../../public/assets/images/semaforo-desktop.png');
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 0px;
          left: 48%;
          transform: translateX(-50%);
          animation: showLight 3s linear;
          -webkit-animation: showLight 3s linear;
          animation-delay: 2s;
          animation-fill-mode: forwards;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);

          @media (max-width: 992px) {
            left: 45%;
            content: url('../../../public/assets/images/semaforo-mobile.png');
          }
        }

        @keyframes line {
          0% {
            opacity: 0.8;
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }

          100% {
            opacity: 1;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }

        @keyframes showLight {
          0% {
            visibility: hidden;
            opacity: 0;
          }

          100% {
            visibility: visible;
            opacity: 1;
          }
        }

        @media (max-width: 992px) {
          margin-bottom: 83px;

        }

        img {
          width: 100%;
          max-width: 200px;

          @media (max-width: 576px) {
            max-width: 130px;

          }

          @media (max-width: 390px) {
            max-width: 120px;

          }
        }
      }
    }

  }

  &.mechanic {
    padding: 72px 0 0 0;
    position: relative;
    z-index: 0;
  }

  &.SectionSpecialAward {
    padding: 72px 0 0 0;
    position: relative;
    z-index: 0;

    article.title-specialAward {
      padding-right: 32px;

      @media (max-width: 992px) {
        margin-bottom: 30px;
      }

      @media (max-width: 768px) {
        padding-right: 0;

      }
    }

    article.art-img-special-award {
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -ms-border-radius: 16px;
      -o-border-radius: 16px;

      img {
        display: block;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
      }

      .circle {
        position: absolute;
        top: var(--y);
        left: var(--x);
        width: 87px;
        height: 87px;
        background: radial-gradient(#5CCC31, transparent, transparent);
        border-radius: 50%;
        pointer-events: none;
        transition: all 2s ease-out;
        animation: fade 1s forwards;
        transform: translate(-50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -webkit-transition: all 2s ease-out;
        -moz-transition: all 2s ease-out;
        -ms-transition: all 2s ease-out;
        -o-transition: all 2s ease-out;
      }

      @keyframes fade {
        0% {
          opacity: 0.3;
          transform: scale(0.5);
          -webkit-transform: scale(0.5);
          -moz-transform: scale(0.5);
          -ms-transform: scale(0.5);
          -o-transform: scale(0.5);
        }

        100% {
          opacity: 0.1;
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }
      }
    }

  }

  &.sectionFaqrs {
    padding: 70px 0 0 0;
    position: relative;
    z-index: 0;

    .linkFaqrs {
      position: relative;
      color: var(--negro-semitico, #000);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-decoration-line: none;
      -moz-text-decoration-line: underline;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: #000000;
      }
    }
  }

  &.sectionTyc {
    padding: 72px 0 72px 0;
    position: relative;
    z-index: 0;

    article {
      border-radius: 16px;
      background: var(--gris-marca, #E1E1DB);
      padding: 32px 10px;
    }

  }

  &.sectionCarEquipment {
    padding-top: 80px;
    position: relative;
    z-index: 0;

    @media (max-width: 992px) {
      padding-top: 40px;

    }
  }

  &.section_Choose_Winner {
    position: relative;
    z-index: 0;
  }

  &.progress {
    padding-top: 134px;
    position: relative;
    z-index: 0;

    .wrap-progress {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      background: var(--blanco-semitico, #FFF);
      width: 100%;
      max-width: 485px;
      box-shadow: 0px 4px 4px 0px rgba(180, 180, 180, 0.75);

      @media (max-width: 992px) {
        padding: 16px 0;
        max-width: 100%;

      }

      .info-progress {
        padding: 24px 12px;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: var(--negro-semitico, #000);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;

          strong {
            font-family: $ZonaPro-ExtraBold;
          }
        }
      }

      .stoplight {
        padding: 24px 12px;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        span.mySpan {
          visibility: hidden;
          opacity: 0;

          &:nth-child(1) {
            animation: showLightProgres 1s linear;
            -webkit-animation: showLightProgres 1s linear;
            animation-delay: 1s;
            animation-fill-mode: forwards;
          }

          &:nth-child(2) {
            animation: showLightProgres 1s linear;
            -webkit-animation: showLightProgres 1s linear;
            animation-delay: 2s;
            animation-fill-mode: forwards;
          }

          &:nth-child(3) {
            animation: showLightProgres 1s linear;
            -webkit-animation: showLightProgres 1s linear;
            animation-delay: 3s;
            animation-fill-mode: forwards;
          }
        }
      }

      @keyframes showLightProgres {
        0% {
          visibility: hidden;
          opacity: 0;
        }

        100% {
          visibility: visible;
          opacity: 1;
        }
      }

      .progress_bar {
        padding: 16px 12px;

      }

      .data_info {
        padding: 0px 12px 0px 12px;
        text-align: center;

        @media (max-width: 768px) {
          padding: 0px 12px 0px 12px;

        }

        p.copy-1,
        .copy-2 {
          margin-bottom: 25px;

          @media (max-width: 768px) {
            font-size: 11px;
          }

          span.style-100 {
            vertical-align: middle;
            margin: 0 2px;
            display: inline-block;
            width: 34px;
            height: 34px;
            padding: 0px;
            border: 1px solid var(--gris-marca, #E1E1DB);
            background: var(--Secundarios-Neutro-9, #FFF);
            font-family: $ZonaPro-Bold;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }

        .copy-2 {
          margin-bottom: 12px;
        }

        .copy-3 {
          margin-top: 12px;
          margin-bottom: 8px;

          @media (max-width: 768px) {
            margin-top: 32px;
            font-size: 11px;
          }
        }

        .copy-4 {
          @media (max-width: 768px) {
            font-size: 11px;
          }
        }

      }
    }

    .banner_progress {
      @media (max-width: 992px) {
        display: none !important;

      }

      img {
        width: 100%;
        max-width: 509px;
      }
    }
  }

  &.section-awards {
    padding-top: 134px;
    position: relative;
    z-index: 0;
  }

  &.not-found {
    padding: 170px 0;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }

    .title-notFound {
      font-family: $ZonaPro-ExtraBold;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 42px;
      padding-bottom: 32px;

      @media (max-width: 992px) {
        font-size: 32px;
        line-height: normal;
      }

      @media (max-width: 360px) {
        font-size: 24px;
        line-height: normal;
      }
    }

    figure {
      text-align: center;
      margin: 0;

      @media (max-width: 768px) {
        text-align: left;

      }

      img.img-Error {
        max-width: 290px;

        @media (max-width: 768px) {
          max-width: 180px;

        }
      }
    }

    .button-notFound-desk {

      @media (max-width: 992px) {
        display: none;
      }
    }

    .button-notFound-mobile {
      display: none;

      @media (max-width: 992px) {
        margin: 80px auto;
        display: block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 63%;
      right: -123px;
      transform: translate(-50%, -50%);
      background-image: url('../../../public/assets/images/Error-404_light.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 170px;
      height: 466px;

      @media (max-width: 768px) {
        background-size: 70%;
        width: 140px;
        right: -106px;
        top: 60%;

      }
    }

  }
}