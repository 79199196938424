.mc-faqs-container {
  margin: 0px 0px;

  .mc-accordeon-container {
    position: relative;
    margin: 14px 0px;

    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #24262A 0%, rgba(23, 50, 106, 0.00) 100%);
    }

    &.active {

      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) !important;
        top: 17px !important;
      }
    }

    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 16px 14px;
      padding-right: 58px;
      box-sizing: border-box;
      color: var(--negro-semitico, #000);
      font-family: $ZonaPro-Bold;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        opacity: 0.8;
      }
    }

    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 16px;
      top: 17px;
      -webkit-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .2s cubic-bezier(.77, 0, .175, 1);
      transition: all .2s cubic-bezier(.77, 0, .175, 1);

      svg {
        width: 100%;
        height: auto;
      }
    }

    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      padding-right: 45px;
      padding-left: 16px;
      text-align: justify;
      color: var(--negro-semitico, #000);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;

      a {
        color: var(--negro-semitico, #000);

        &:hover {
          font-weight: $ZonaPro-Bold !important;
        }

      }

      ul {
        list-style: none;

        li {
          padding-bottom: 16px;
        }
      }
      p{
        padding-bottom: 16px;
      }

      /* 110% */
      @media (max-width: 768px) {
        padding-right: 12px;

      }
    }
  }

  &.mc-faqs-container-type-1 {}

  &.mc-faqs-container-type-2 {}
}