.mc-header-container {
  position: absolute;
  width: 100%;
  top: auto;
  height: 76.73px;
  border-radius: 0px 0px 46px 46px;
  background: var(--gris-marca, #E1E1DB);
  z-index: 1;

  &.top-auto{
    top: 0 !important;
  }

  @media only screen and (min-width: 992px) {
    z-index: 1;
  }

  &__menu {
    height: 100%;
    z-index: 2;
  }

  &__submenu {
    position: relative;
    height: 60px;
    background-color: rgb(141, 141, 141);

    @media only screen and (min-width: 992px) {
      z-index: 1;
    }

    &.not-login {
      display: none;
    }
  }

  &__logo {
    z-index: 1;
    width: 248.951px;
    text-align: center;
    height: 100%;
    max-width: 248.951px;

    @media (max-width: 992px) {
      width: 129.62px;

    }

    img {
      width: 100%;
      height: auto;
    }

    &.logoMc{
      width: 60px;
      display: none;
      @media (max-width: 992px) {
       display: block;
      }
      &.showLogo{
        display: block;
      }
    }
  }

  &__mobile--close {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    height: 100%;


    .logos-bancos {
      width: 100%;
    }

    .icon-close_session {
      width: 26px;
      height: 26px;
      position: relative;
      display: inline-block;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: 992px) {
      text-align: center;
      height: 42px;
      bottom: 0px;
      margin: auto;
      width: 127px;
      max-width: 127px;
    }
  }

  &__mobile--submenu {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;
    cursor: pointer;

    .icon-menu {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }

  &__nav {
    box-sizing: border-box;

    @media (max-width: 992px) {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      border-radius: var(--Sizes-l, 24px) 25px 0px 0px;
      background: var(--gris-marca, #E1E1DB);
      z-index: 3;
      padding: 16px 10px 40px 10px;
      
    }
    @media (max-width: 390px) {
      padding: 16px 5px 40px 5px;
     
    }

    ul {
      padding: 0px;
      margin: 0px;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 2;

      li {
        float: left;
        width: 25%;
        height: 100%;
        list-style: none;
        text-align: center;

        @media only screen and (min-width: 992px) {
          width: auto;
          float: none;
          display: inline-block;
          vertical-align: middle;
          margin: 0 14px;
        }

        a,
        .mc-header-container__nav--button {
          display: block;
          height: 100%;
          width: 100%;
          cursor: pointer;
          text-decoration: none;
          color: var(--negro-semitico, #000);          
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          @media (max-width: 576px) {
            font-size: 12px;
           
          }
          @media (max-width: 360px) {
            font-size: 10px;
           
          }

         

          .mc-icon {
            @media only screen and (min-width: 992px) {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }

        &.mc-header-container__nav--item-active {
          a {
            u {
              text-decoration: none !important;
              border-bottom: 2px solid #000;
              font-family: $ZonaPro-ExtraBold;              
              font-style: normal;
              font-weight: 800;
              line-height: 24px;
            }
          }

          * {
            color: $colorText;
          }

          .mc-icon {
            svg {
              path {
                stroke: $colorText;
              }
            }
          }
        }

        .logo_mc{
          img{

            width: 60px;
          }
        }
        .logoMc {
          img{
            width: 60px !important;

          }
        }
      }
    }

    &--extra {
      position: fixed;
      right: -20px;
      bottom: 117px;
      z-index: 1;
      box-sizing: border-box;
      padding: 12px 20px;
      text-align: right;
      min-width: 200px;
      outline: 1px solid rgb(230, 230, 230);
      visibility: hidden;
      opacity: 0;

      border-radius: var(--M, 16px);
      border: 1px solid var(--gris-secundario, #B4B4B4);
      background: var(--Secundarios-Neutro-9, #FFF);

      @media only screen and (min-width: 992px) {
        position: absolute;
        top: 70px;
        bottom: auto;
        right: 220px !important;
        outline: transparent;
        -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        text-align: left;
      }

      a,
      .mc-header-container__nav--close {
        display: block;
        padding: 10px 0px;
        text-decoration: none;
        cursor: pointer;
        color: var(--negro-semitico, #000);
        text-align: left;
        font-family: $ZonaPro-Bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;

        &:hover {
          opacity: 0.7;
        }
      }

      a span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
      }

      .mc-header-container__nav--close {
        border-top: 1px solid #B4B4B4;
        margin-top: 6px;
        padding-top: 13px;
        display: none !important;

        @media (max-width: 992px) {
          display: flex !important;
          justify-content: flex-end;
          gap: 4px;
          align-items: center;
          color: var(--rojo-semitico, var(--Primario-Primario-oscuro, #C90C14));          
          font-family: $ZonaPro-Bold;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px;
          /* 116.667% */

        }
      }

      &.opened {
        visibility: visible;
        opacity: 1;
        right: 0px;
      }

    }
  }

  &.mc-header-container-type-2 {

    .mc-header-container__menu {
      text-align: center;

      @media only screen and (min-width: 992px) {
        height: 110px;
      }

      .mc-header-container__logo {
        left: 0px;
        right: 0px;
        margin: auto;

        @media only screen and (min-width: 992px) {
          height: 50%;
          width: 100%;
          max-width: 230px;
        }
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          left: 0px;
          right: 0px;
          text-align: center;
          top: auto;
          bottom: 0px;
          height: 50%;

          .mc-header-link {
            line-height: 52px;
          }

          .mc-header-container__nav--extra {
            right: -32% !important;
            left: 0;
            width: 300px;
            margin: auto;
            top: 55px;
          }
        }

        ul {
          li {
            .mc-icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
            }

            .mc-app-centry {
              &>span {
                display: inline-block;
                padding: 5px 7px;
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                &>span {
                  background-color: #000;
                  color: #fff;

                  .mc-icon {
                    svg {
                      path {
                        fill: #fff !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-3 {

    .mc-header-container__menu {
      text-align: left;

      @media only screen and (min-width: 992px) {
        background-color: transparent !important;
      }

      .mc-header-container__logo {
        left: 20px;
        right: auto;
        margin: auto;
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          background-color: rgb(141, 141, 141);
          right: 0px;
          left: auto;
          width: auto;
          -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);

          .mc-header-container__nav--extra {
            right: 0px !important;
          }
        }

        ul {
          li {

            a,
            .mc-header-container__nav--button {
              &:hover {
                opacity: 1;
              }
            }

            .mc-header-container__nav--button {
              .mc-app-centry {
                &>span {
                  .mc-icon {
                    display: none !important;

                    @media only screen and (min-width: 992px) {
                      display: inline-block !important;
                      position: relative;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }

            .mc-icon {
              position: absolute;
              left: 0px;
              right: 0px;
              top: -26px;
              background-color: #fff;
              border-radius: 100px;
              width: 40px;
              height: 40px;
              text-align: center;
              margin: auto;
              -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              opacity: 0;

              @media only screen and (min-width: 992px) {
                position: relative;
                top: auto;
                background-color: transparent;
                -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                opacity: 1;
                width: 35px;
                height: 35px;
                top: 4px;
              }

              &>span {
                position: relative;
                top: 8px;

                @media only screen and (min-width: 992px) {
                  top: auto;
                }
              }
            }

            .mc-app-centry {
              &>span {
                display: inline-block;
                padding: 5px 7px;

                @media only screen and (min-width: 992px) {
                  top: -4px;
                  position: relative;
                }
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                &>span {
                  color: #fff;

                  .mc-icon {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-4 {

    @media only screen and (min-width: 992px) {
      z-index: 9;
    }

    @media only screen and (min-width: 992px) {
      .mc-header-container__menu {
        .mc-header-container__logo {
          left: 0px;
          right: 0px;
          margin: auto;
        }

        .mc-header-container__mobile--submenu {
          .mc-icon {
            svg {
              transform: scale(0.85);
            }
          }
        }

        .mc-header-container__nav {
          ul {
            @media only screen and (min-width: 992px) {
              padding-right: 10px;
            }
          }
        }
      }
    }

    .mc-header-container__nav {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 999;
      height: auto;
      width: auto;
      background-color: transparent;
      visibility: hidden;
      -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      transition: all .3s cubic-bezier(.77, 0, .175, 1);

      .mc-header-container__mobile--overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        visibility: hidden;
        opacity: 0;
      }

      .mc-header-container__nav--close {
        position: absolute;
        right: -15px;
        top: 12px;
        color: $colorText;
        display: block;
        text-align: right;
        z-index: 3;
        padding: 16px 26px;
        box-sizing: border-box;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
      }

      ul {
        background-color: #fff;
        right: -280px;
        top: 0px;
        width: 280px;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        padding-top: 60px;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        visibility: hidden;
        opacity: 0;

        li {
          float: none;
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: auto;
          text-align: right;
          padding: 0 30px;

          a {
            height: 60px;

            .mc-app-centry {
              &>span {
                color: $colorText;
                text-align: left;

                .mc-icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;

                  svg {
                    path {
                      stroke: $colorText;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mc-header-container__nav--extra {
        display: none !important;
      }
    }

    &.show-menu {
      .mc-header-container__nav {
        visibility: visible !important;

        .mc-header-container__mobile--overlay,
        .mc-header-container__nav--close {
          visibility: visible !important;
          opacity: 1 !important;
          right: 0px !important;
        }

        ul {
          right: 0px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.mc-header-link {
  line-height: 65px;
  font-size: 15px;
  font-weight: 600;
}

.header-items {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;

    &.logo_mc {
      display: none;
    }
  }

  u {
    text-decoration: none !important;
    border-bottom: 1px solid #000;

    &:hover {
      font-family: $ZonaPro-Bold;
      border-bottom: 2px solid #000;
    }
  }

}

.closeSession {
  cursor: pointer;

  @media (max-width: 992px) {
    display: none !important;

  }

  display: flex;

  u {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none !important;
  }
}