.awardStock {
    box-sizing: border-box;
    width: 100%;
    max-width: 470px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    padding: 16px 12px;
    border-radius: 8px;
    border: 2px solid var(--gris-secundario, #B4B4B4);
    background: var(--gris-marca, #E1E1DB);
    margin-bottom: 72px;

    .data_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-child(2) {
            border-right: 1px solid #000000;
            border-left: 1px solid #000000;
            padding: 0 12px;
        }

        .title {
            color: var(--negro-semitico, #000);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .value_stock-1,
        .value_stock-2,
        .value_stock-3 {
            
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 28px;
            font-family: $ZonaPro-ExtraBold;

        }
        .value_stock-1{
            color: var(--rojo-semitico, var(--Primario-Primario-oscuro, #C90C14));
        }
        .value_stock-2{
            color: var(--cta, #5EBE38);
        }
        .value_stock-3{
            color: var(--cta, #000000);
        }
    }
}