.mc-login {
  position: relative;
  height: auto;
  // min-height: 400px;

  &__form {
    position: relative;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    // max-width: 400px;

    &--fields {
      position: relative;
      // margin-bottom: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    &--overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;

      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

.mc-input-container {
  @media (max-width: 992px) {
    width: 100%;
  }

  &__box {
    position: relative;
    // margin-bottom: 22px;

    label {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      display: block;
      margin-bottom: 12px;
    }

    input,
    textarea,
    select {
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      border: 0px;
      outline: 0px;
      height: 50px;
      position: relative;
      border-radius: 6px;
      font-size: 16px;
      padding: 0 12px;
      min-width: 100%;
      max-width: 100%;
      border: 1px solid #C2C2C2;
      color: #919191;

      &[type="date"] {
        text-transform: uppercase;
      }
    }

    input {
      width: 326px;
      max-width: 326px;
      padding: 0px 15px;
      border-radius: 6px;
      border: 2px solid var(--gris-secundario, #B4B4B4);
      background: var(--gris-marca, #E1E1DB);
      height: 48px !important;
      
      @media (max-width: 992px) {
        width: 100%;
        height: 55px !important;

      }

      &::placeholder {
        color: var(--gris-secundario, #B4B4B4);
        font-kerning: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 1.25px;
        text-decoration-line: underline;
        -moz-text-decoration-line: underline;

        @media(max-width: 300px) {
          font-size: 12px;

        }
      }
    }

    .icon-eyes-password {
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);

      .mc-icon {
        display: inline-block;
        width: 24px;
        height: 24px;

      }

      .icon-eyes {
        display: inline-block;
        width: 24px;
        height: 24px;

        &-hide {
          display: inline-block;
          width: 24px;
          height: 24px;

        }

      }
    }

    textarea {
      padding-top: 5px;
      padding-bottom: 5px;
      resize: none;
      height: 130px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: none;
      background-size: 0px;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder {
      opacity: 1 !important;
    }

    &.terms {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px !important;
      cursor: pointer;
      font-size: 16px !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: normal !important;
      line-height: 8px;

      a {
        text-decoration: none;
        margin-left: 4px;
        color: $colorText;
        cursor: initial !important;
        font-size: 13px;
      }

      input {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 2;
        opacity: 0;
      }

      label {
        display: inline;
        font-size: 13px;
        color: $colorText;
      }

      .mc-input-checkbox__checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #919191;
      }

      .mc-input-error {
        margin-top: 14px;
        line-height: 16px !important;
      }
    }

    &.terms:hover input~.mc-input-checkbox__checkmark {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &.terms input:checked~.mc-input-checkbox__checkmark {
      background-color: $primaryColor;
      border: 2px solid $primaryColor !important;
    }

    .mc-input-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    &.terms input:checked~.mc-input-checkbox__checkmark:after {
      display: block;
    }

    &.terms .mc-input-checkbox__checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg) scale(0.8);
      -ms-transform: rotate(45deg) scale(0.8);
      transform: rotate(45deg) scale(0.8);
    }

    .mc-input-by-character {
      input {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        min-width: 20px;
        margin: 0 1%;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.mc-input-separated-container {
  input {
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 1%;
    color: #000;
  }
}

.mc-input-container__box--error {
  input {
    border-radius: 6px;
    border: 2px solid var(--Primario-Primario-oscuro, #C90C14) !important;
    background: var(--gris-marca, #E1E1DB);
    transition: border .3s linear;
    -webkit-transition: border .3s linear;
    -moz-transition: border .3s linear;
    -ms-transition: border .3s linear;
    -o-transition: border .3s linear;
  }
}

.mc-input-error {
  width: 100%;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  color: var(--rojo-semitico, var(--Primario-Primario-oscuro, #C90C14));
  font-kerning: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-decoration-line: underline;
  animation: show_error .3s linear;
  -webkit-animation: show_error .3s linear;
  transition: height .3s linear;
  -webkit-transition: height .3s linear;
  -moz-transition: height .3s linear;
  -ms-transition: height .3s linear;
  -o-transition: height .3s linear;

  @media(max-width: 768px) {
    max-width: 100%;

  }

  @keyframes show_error {
    0% {
      height: 0px;

    }

    100% {
      height: 32px;

    }
  }

}