.mc-cardbox-container {

  &.mechanic_login {

    position: relative;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    margin: 5px;
    width: fit-content;

    &>img {
      width: 100%;

      height: auto;
      margin: 0 auto;
      display: block;
    }

    &>h2 {
      font-size: 20px;
      color: $primaryColor;
      text-align: center;
    }

    &>div {
      font-size: 15px;
      color: $colorText;
      text-align: center;
    }

    .content-card {
      padding: 10px 16px 30px 16px;
      background: var(--gris-marca, #E1E1DB);
      min-height: 145px;
      border-radius: 0px 0px 16px 16px;
      background: var(--gris-marca, #E1E1DB);

      @media (max-width: 768px) {
        min-height: 128px;
        padding: 10px 10px 30px 10px;

      }

      div {
        color: var(--negro-semitico, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        text-align: left;

        p {
          margin: 0;

          strong {
            font-weight: bold;
            font-family: $ZonaPro-ExtraBold;
          }
        }
      }
    }

  }


  &.car_equipment {
    border-radius: 8px;
    border: 2px solid var(--gris-marca, #E1E1DB);
    background: var(--gris-marca, #E1E1DB);
    padding: 0;
    margin: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      margin: 35px 0;

    }


    &:hover {
      img {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
      }

    }

    img {
      width: 100%;
      max-width: 100px;
      padding: 0 24px;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transition: transform .3s ease;
      -webkit-transition: transform .3s ease;
      -moz-transition: transform .3s ease;
      -ms-transition: transform .3s ease;
      -o-transition: transform .3s ease;

      @media (max-width: 768px) {
        padding: 0 12px;

      }
    }

    .content-card {
      width: 100% !important;
      height: 142px;
      padding: 0 12px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 8px 8px 0px;
      -webkit-border-radius: 0px 8px 8px 0px;
      -moz-border-radius: 0px 8px 8px 0px;
      -ms-border-radius: 0px 8px 8px 0px;
      -o-border-radius: 0px 8px 8px 0px;

      @media (max-width: 768px) {
        height: 136px;
        margin-bottom: 10px;
      }



      h3 {
        font-size: $rem-20;
      }
    }

    .slick-slider .custom-prev-arrow {
      display: none !important;
    }
  }
}