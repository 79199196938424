.Title {
  &-custom {
    &-h2 {
      color: var(--negro-semitico, #000);
      font-size: 32px;
      margin: 0;
      margin-bottom: 32px;
      line-height: 36px;

      @media (max-width: 768px) {
        color: var(--negro-semitico, #000);
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        font-family: $ZonaPro-ExtraBold;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    &-h3 {
      color: var(--negro-semitico, #000);
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      font-family: $ZonaPro-ExtraBold;
      line-height: 20px;
      margin: 0;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        font-size: 20px;

      }
    }

    &-h4 {
      color: var(--negro-semitico, #000);    
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      font-family: $ZonaPro-ExtraBold;
      line-height: normal;
      margin-bottom: 32px;
    }
  }
}