.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background: rgba(0, 36, 75, 0.75);
  }

  &__center {
    // width: 430px;
    height: 100%;
    margin: 0 auto;    
    max-width: 378px;
    z-index: 1;

    &--window {
      position: relative;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      box-sizing: border-box;
      padding: 24px 19px;
      border-radius: 16px;
      background: var(--Secundarios-Neutro-9, #FFF);
      box-shadow: 0px 4px 32px 0px rgba(224, 194, 104, 0.27);

      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }

      &__title {
        text-align: center;
        margin: 24px 0px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $colorText;
      }

      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 18px 0;
      }

      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }

      &__terms {
        text-align: center;
        margin-bottom: 16px;

        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          text-decoration-line: underline;
          color: $colorText;
        }
      }
    }

    &.modalRedeem {
      max-width: 405px;

      .mc-custom-modal__center--window {
        border-radius: 16px;
        background: var(--Secundarios-Neutro-9, #FFF);
        box-shadow: 0px 4px 25px 0px rgba(214, 214, 214, 0.40);

        .modal_header {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 18px;
          padding: 16px 0;

          .mc-custom-modal__center--window__image {
            width: 84.878px;
            height: 80px;
            margin: 0;
          }

          .mc-custom-modal__center--window__desc {
            text-align: left;
            color: var(--negro-semitico, #000);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            .name,
            .desc-name {
              margin-bottom: 9px;
            }
          }

          .mc-custom-modal__center--window__title {
            color: var(--negro-semitico, #000);
            text-align: left;
            font-family: $ZonaPro-ExtraBold;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            margin: 0;
          }
        }

        .modal_body {
          text-align: center;

          .mc-custom-modal__center--window__auth {
            .desc_auth {
              color: var(--negro-semitico, #000);
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
              margin-bottom: 10px;
            }

            .lbl_twoStepAuth {
              text-align: left;
              color: var(--negro-semitico, #000);
              font-family: $ZonaPro-ExtraBold;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              margin-bottom: 4px;
            }

            .mc-input-container__box input {
              border-radius: var(--s, 8px);
              border: 1px solid var(--gris-secundario, #B4B4B4);
              background: var(--Secundarios-Neutro-9, #FFF);
              text-transform: lowercase;
              color: var(--gris-secundario, #B4B4B4);
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
            }
          }
        }

        .mc-custom-modal__center--window__actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          margin: 16px 0;
        }
      }
    }
 
    &.pushInfoTIA{
      .mc-custom-modal__center--window{
        padding: 0;
        .mc-custom-modal__center--window__image{
          width: 100%;
          height: 178px;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          background-size: 100% !important;
          border-radius: 16px 16px 0 0;
          -webkit-border-radius: 16px 16px 0 0;
          -moz-border-radius: 16px 16px 0 0;
          -ms-border-radius: 16px 16px 0 0;
          -o-border-radius: 16px 16px 0 0;
}
        .modal_body{
          padding: 0 16px ;
          p{
            display: flex;
            margin-bottom: 12px;
          }
        }
        .mc-custom-modal__center--window__actions{
          padding: 0 16px;
          box-sizing: border-box;
        }
      }
    }

  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}
.desc{
  strong{
    font-family: $ZonaPro-ExtraBold;
  }
}