.SectionChooseWinner {
  .wrap-section {
    .img-section {
      padding: 0px 0 0 16px;
  

      @media (max-width: 768px) {
        margin-top: 20px;
        padding: 0px 0 0 0px;
      }

      img {
        width: 100%;
        max-width: 600px;
      }
    }

    .description {
      height: 100%;
      padding-top: 24px;
      padding-right: 16px;
      max-width: 450px;
      width: auto;
      font-weight: 600;

      @media (max-width: 992px) {
        padding-top: 0px;
        font-size: 18px;
        text-align: center;

      }
    }
  }
}