.mc-banner-container {
  padding: 150px 0 100px 0;
  background: rgba(0, 36, 75, 0.75);
  background-image: url('../../../public/assets/images/bg-car-login.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
  @media (max-width: 450px) {
    background-image: url('../../../public/assets/images/bg-car-login-mobile.png');        
    background-position: top center !important;
  }
  @media (max-width: 992px) {
    background-position: bottom center;
  }

  @media (min-width: 1366px) {
    padding: 200px 0 200px 0;
  }

  @media (max-width: 992px) {
    padding: 70px 0 40px 0;
  }

  .imgTitle {
    padding-top: 25px;
    margin-bottom: 30px;
    position: relative;

    // overflow: hidden;
    &::after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0px;
      left: -50px;
      background-color: #ffffffc1;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      animation: line 2s linear;
      -webkit-animation: line 2s linear;
      animation-fill-mode: forwards;

      @media (max-width: 992px) {
        left: -21px;
      }

      @media (max-width: 576px) {
        left: -22px;
      }
      @media (max-width: 450px) {
        left: -48px;
      }
    }

    &::before {
      content: url('../../../public/assets/images/semaforo-desktop.png');      
      visibility: hidden;
      position: absolute;
      top: 0px;
      left: 48%;
      transform: translateX(-50%);
      animation: showLight 2s linear;
      -webkit-animation: showLight 2s linear;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);

      @media(max-width: 992px) {
        left: 50%;
        content: url('../../../public/assets/images/semaforo-mobile.png');
      }
      @media(max-width: 450px) {
        left: 39%;
        content: url('../../../public/assets/images/semaforo-mobile.png');
      }
    }

    @keyframes line {
      0% {
        opacity: 0.8;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
      }

      100% {
        opacity: 1;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }

    @keyframes showLight {
      0% {
        visibility: hidden;

      }

      100% {
        visibility: visible;

      }
    }

    @media (max-width: 992px) {
      margin-bottom: 83px;

    }

    img {
      width: 100%;
      max-width: 200px;

      @media (max-width: 992px) {
        max-width: 112px;

      }
    }
  }

  .mainTitle {
    @media (min-width: 1280px) {
      padding-top: 48px;
      position: relative;
      top: 74px;
    }

    h2 {
      color: #FFFFFF;
    }
  }

  .description {
    @media (min-width: 1280px) {
      position: relative;
      top: 74px;
    }

    p {
      font-family: $ZonaPro-Bold;
      color: #FFFFFF;
      font-size: $rem-28;

      @media (max-width: 992px) {
        font-size: $rem-18;
        margin-top: 20px;

      }
    }
  }

  &.PremioMayor {
    background-image: url('../../../public/assets/images/bg-car-premio-mayor.png');
    @media (max-width: 450px) {
      background-image: url('../../../public/assets/images/bg-car-login-mobile.png');
      background-position: top;
     
    }

    @media (min-width: 1366px) {
      padding: 150px 0 200px 0;
      background-position: 28% 70% !important;
    }

    .show-text{
      display: none;
      @media (max-width: 992px) {
       display: inline;
      }
    }
    .hide-text{
      display: inline;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}