.tabs {
    box-sizing: border-box;

    .tab-buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            cursor: pointer;
            height: 54px;
            width: 100%;
            max-width: 237px;
            padding: 16px;
            border-radius: 12px 12px 0px 0px;
            background: var(--gris-marca, #E1E1DB);
            -webkit-border-radius: 12px 12px 0px 0px;
            -moz-border-radius: 12px 12px 0px 0px;
            -ms-border-radius: 12px 12px 0px 0px;
            -o-border-radius: 12px 12px 0px 0px;

            color: var(--gris-secundario, #B4B4B4);
            font-family: $ZonaPro-Thin;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            line-height: 22px;
            transition: all .3s linear;
            -webkit-transition: all .3s linear;
            -moz-transition: all .3s linear;
            -ms-transition: all .3s linear;
            -o-transition: all .3s linear;

            &:hover {
                background: var(--gris-marca, #E1E1DB);
                box-shadow: 0px -4px 13px 0px rgba(180, 180, 180, 0.75);
                color: var(--negro-semitico, #000);

            }

            &.active {
                font-family: $ZonaPro-Bold;
                color: var(--negro-semitico, #000);
                text-align: center;                
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                background: var(--gris-secundario, #B4B4B4);

                &:hover {
                    box-shadow: none;


                }
            }

            @media (max-width: 768px) {
                padding: 8px;
                font-size: 18px;
            }
        }

    }

    .tab-content {
        padding: 24px 0;
        opacity: 1;
        visibility: visible;
    }
}