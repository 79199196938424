@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&family=PT+Sans+Narrow:wght@400;700&display=swap'
);
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  background: #F4F3F3;
  font-family: $ZonaPro-Thin !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $ZonaPro-Bold;
    margin: 0;
  }

  p {
    margin: 0;
    font-family: $ZonaPro-Thin;
    // font-weight: 600 !important;
  }
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.full-height {
  height: 100%;
}

.mc-tag-card {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-color: $primaryColor;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  padding-bottom: 3px;

  &.position-left {
    right: auto;
    left: 10px;
  }
}


.br-responsive {
  &-sm {
    @media (min-width: 576px) {
      display: none !important;
    }
  }

  &-md {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &-lg {
    @media (min-width: 992px) {
      display: none;
    }
  }
}


/* Alineación para pantallas de cualquier tamaño (xs) */
.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

/* Alineación para pantallas pequeñas (sm) */
@media (min-width: 576px) {
  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }
}

/* Alineación para pantallas medianas (md) */
@media (min-width: 768px) {
  .text-md-start {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-end {
    text-align: right;
  }

  .text-md-justify {
    text-align: justify;
  }
}

/* Alineación para pantallas grandes (lg) */
@media (min-width: 992px) {
  .text-lg-start {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .text-lg-end {
    text-align: right;
  }

  .text-lg-justify {
    text-align: justify;
  }
}

/* Alineación para pantallas extra grandes (xl) */
@media (min-width: 1200px) {
  .text-xl-start {
    text-align: left;
  }

  .text-xl-center {
    text-align: center;
  }

  .text-xl-end {
    text-align: right;
  }

  .text-xl-justify {
    text-align: justify;
  }
}

.color {
  &-white {
    color: #FFFFFF;
  }

  &-blue2 {
    // color: $blue-secondary2;
  }

  &-blue-primary-1 {
    // color: $blue-primary1;
  }

  &-blue-light {
    // color: $blue-light;
  }

  &-blue-light2 {
    // color: $blue-light2;
  }

  &-red {
    // color: $red;
  }
}

.bg {
  &-black {
    background-color: #000000 !important;
  }

  &-text {
    padding: 0 2px;
    border-radius: 4px;
    background: var(--amarillo-marca, #D0A830);
    font-family: $ZonaPro-Bold;
  }

  &-line {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0px;
      height: 3px;
      width: 100%;
      background: radial-gradient(circle, rgba(65, 132, 39, 1) 0%, rgba(225, 225, 219, 1) 100%);
      border-radius: 1.97px;
      -webkit-border-radius: 1.97px;
      -moz-border-radius: 1.97px;
      -ms-border-radius: 1.97px;
      -o-border-radius: 1.97px;
    }

    &-middle {
      &::after {
        content: '';
        position: absolute;
        bottom: 20px;
        left: 50%;
        height: 3px;
        width: 20%;
        background: radial-gradient(circle, rgba(65, 132, 39, 1) 0%, rgba(225, 225, 219, 1) 100%);
        border-radius: 1.97px;
        transform: translateX(-50%);
        -webkit-border-radius: 1.97px;
        -moz-border-radius: 1.97px;
        -ms-border-radius: 1.97px;
        -o-border-radius: 1.97px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }


  }
}

.border {
  &-5 {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  &-10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  &-15 {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }

  &-20 {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  &-24 {
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
  }

  &-25 {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }

  &-50 {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }

  &-bottom {
    &-64 {
      border-bottom-left-radius: 64px;
      border-bottom-right-radius: 64px;
      background: #FFFFFF !important;

      // border-bottom: solid 1.5px grey;
      @media (max-width: 992px) {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

      }

      &-md {
        @media (min-width: 992px) {
          background: none !important;
        }
      }
    }
  }
}

.line {
  &-normal {
    line-height: normal;
  }

  &-12 {
    line-height: $rem-12;
  }

  &-13 {
    line-height: $rem-13;
  }

  &-18 {
    line-height: $rem-18;
  }

  &-22 {
    line-height: $rem-24;
  }

  &-24 {
    line-height: $rem-24;
  }

  &-26 {
    line-height: $rem-26;
  }

  &-28 {
    line-height: $rem-28;
  }

  &-38 {
    line-height: $rem-38;
  }

  &-40 {
    line-height: $rem-40;

    @media (max-width: 768px) {
      line-height: normal;
    }
  }

  &-48 {
    line-height: $rem-48;
  }

  &-50 {
    line-height: $rem-50;
  }

  &-52 {
    line-height: $rem-52;
  }

  &-55 {
    line-height: $rem-55;
  }

  &-60 {
    line-height: $rem-60;
  }

  &-64 {
    line-height: $rem-64;
  }

  &-69 {
    line-height: $rem-69;
  }
}

.decoration {
  &-underline {
    text-decoration: underline;

  }

  &-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}

// Display classes
.display-contents {
  display: contents;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.display-grid {
  display: grid;
}

.display-inline-grid {
  display: inline-grid;
}

.display-table {
  display: table;
}

.display-table-row {
  display: table-row;
}

.display-table-cell {
  display: table-cell;
}

.display-none {
  display: none;
}

// Responsive display utilities
@media (max-width: 576px) {
  .display-sm-none {
    display: none !important;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .display-md-none {
    display: none !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .display-lg-none {
    display: none !important;
  }
}

@media (min-width: 993px) {
  .display-xl-none {
    display: none !important;
  }
}

.flex {
  display: flex;

  &-direction-column {
    flex-direction: column;
  }

  &-justify-start {
    justify-content: flex-start;
  }

  &-justify-end {
    justify-content: flex-end;
  }

  &-justify-center {
    justify-content: center;
  }

  &-justify-space-around {
    justify-content: space-around;
  }

  &-justify-space-between {
    justify-content: space-between;
  }

  &-justify-space-evenly {
    justify-content: space-evenly;
  }

  &-align-start {
    align-items: flex-start;
  }

  &-align-end {
    align-items: flex-end;
  }

  &-align-center {
    align-items: center;
  }
}

.font {
  &-thin {
    font-family: $ZonaPro-Thin !important;
    font-weight: 600 !important;
  }

  &-light {
    font-family: $ZonaPro-Light !important;
  }

  &-bold {
    font-family: $ZonaPro-Bold !important;
  }

  &-extra-bold {
    font-family: $ZonaPro-ExtraBold !important;
  }

}

.gap{
  &-10{
    gap: 10px !important;
  }
}

.size {
  &-10 {
    font-size: $rem-10;
  }

  &-11 {
    font-size: $rem-11;
  }

  &-12 {
    font-size: $rem-12 !important;
  }

  &-13 {
    font-size: $rem-13 !important;
  }

  &-14 {
    font-size: $rem-14 !important;
  }

  &-16 {
    font-size: $rem-16 !important;
  }

  &-18 {
    font-size: $rem-18 !important;
  }

  &-20 {
    font-size: $rem-20 !important;

    &-sm-14 {
      @media (max-width: 576px) {
        font-size: $rem-14 !important;

      }
    }
  }

  &-22 {
    font-size: $rem-22;

    @media (max-width: 992px) {
      &-lg-18 {
        font-size: $rem-18;
      }
    }
  }

  &-24 {
    font-size: $rem-24 !important;
  }

  &-26 {
    font-size: $rem-26;
  }

  &-28 {
    font-size: $rem-28;
  }

  &-30 {
    font-size: $rem-30;
  }

  &-32 {
    font-size: $rem-32;

    &-mb-24 {
      @media (max-width: 768px) {
        font-size: $rem-24;
      }
    }

    &-mb-20 {
      @media (max-width: 576px) {
        font-size: $rem-20;
      }
    }
  }

  &-31 {
    font-size: $rem-31;
  }

  &-36 {
    font-size: $rem-36 !important;

    &-sm-25 {
      @media (max-width: 576px) {
        font-size: $rem-25 !important;

      }

    }
  }

  &-38 {
    font-size: $rem-38 !important;

    &-sm-25 {
      @media (max-width: 576px) {
        font-size: $rem-25 !important;

      }
    }
  }

  &-40 {
    font-size: $rem-40;
  }

  &-42 {
    font-size: $rem-42;

    &-sm-25 {
      @media (max-width: 576px) {
        font-size: $rem-25;

      }

    }
  }

  &-48 {
    font-size: $rem-48;

    &-mb-32 {
      @media (max-width: 576px) {
        font-size: $rem-32;
      }
    }
  }

  &-50 {
    font-size: $rem-50;

    &-sm-30 {
      @media (max-width: 576px) {

        font-size: $rem-30;
      }

    }
  }

  &-52 {
    font-size: $rem-52;
  }

  &-53 {
    font-size: $rem-53;

    &-lg-26 {
      @media (max-width: 992px) {
        font-size: $rem-26;

      }
    }
  }

  &-54 {
    font-size: $rem-54;
  }

  &-56 {
    font-size: $rem-56;

    @media (max-width: 768px) {
      font-size: $rem-40;

    }
  }

  &-58 {
    font-size: $rem-58;

    &-lg-26 {
      @media (max-width: 992px) {
        font-size: $rem-26;

      }

    }
  }

  &-60 {
    font-size: $rem-60;
  }

  &-63 {
    font-size: $rem-63;
  }

  &-64 {
    font-size: $rem-64;

    @media (max-width: 768px) {
      font-size: $rem-48;

    }
  }

  &-65 {
    font-size: $rem-65;
  }

  &-69 {
    font-size: $rem-69;
  }

  &-72 {
    font-size: $rem-72;

    @media (max-width: 768px) {
      font-size: $rem-48;

    }
  }

  &-78 {
    font-size: $rem-78 !important;

    &-lg-37 {
      @media (max-width: 992px) {
        font-size: $rem-37 !important;

      }

    }
  }

  &-80 {
    font-size: $rem-80;
  }
}

$spacings: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
  6: 3.5rem,
  7: 4rem,
  8: 4.5rem,
);

@mixin set-margin($direction, $size) {
  @if map-has-key($spacings, $size) {
    margin-#{$direction}: map-get($spacings, $size) !important;
  }

  @else {
    @warn "Size #{$size} is not defined in $spacings map.";
  }
}

@mixin set-padding($direction, $size) {
  @if map-has-key($spacings, $size) {
    padding-#{$direction}: map-get($spacings, $size) !important;
  }

  @else {
    @warn "Size #{$size} is not defined in $spacings map.";
  }
}

// margin
@each $size, $value in $spacings {
  .mt-#{$size} {
    @include set-margin(top, $size);
  }

  .mb-#{$size} {
    @include set-margin(bottom, $size);
  }

  .ml-#{$size} {
    @include set-margin(left, $size);
  }

  .mr-#{$size} {
    @include set-margin(right, $size);
  }

  .mx-#{$size} {
    @include set-margin(left, $size);
    @include set-margin(right, $size);
  }

  .my-#{$size} {
    @include set-margin(top, $size);
    @include set-margin(bottom, $size);
  }
}

// padding
@each $size, $value in $spacings {
  .pt-#{$size} {
    @include set-padding(top, $size);
  }

  .pb-#{$size} {
    @include set-padding(bottom, $size);
  }

  .pl-#{$size} {
    @include set-padding(left, $size);
  }

  .pr-#{$size} {
    @include set-padding(right, $size);
  }

  .px-#{$size} {
    @include set-padding(left, $size);
    @include set-padding(right, $size);
  }

  .py-#{$size} {
    @include set-padding(top, $size);
    @include set-padding(bottom, $size);
  }
}

.m-0 {
  margin: 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}

.p-0 {
  padding: 0 !important;
}

.fit-content {
  width: fit-content !important;
  padding: 0px 48px !important;
  margin: 0 auto;

  @media (max-width: 576px) {
    width: 100% !important;
    padding: 0px 0px !important;

  }
}

.fill-content {
  width: -webkit-fill-available !important;
}

.w {
  &-65 {
    width: 65% !important;

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }

  &-75 {
    width: 75% !important;

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }

  &-50 {
    width: 50% !important;

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }
  &-100 {
    width: 100% !important;
  }
}

.h {
  &-43 {
    height: 43px !important;
  }

  &-45 {
    height: 45px !important;
  }

  &-48 {
    height: 48px !important;

    @media (max-width: 768px) {
      height: 55px !important;

    }
  }
}

.mt72{
  margin-top: 72px !important;
}