.mc-progressBar-container {
  position: relative;
  height: 34px;
  background: transparent;
  padding: 10px 10px 10px 14px;
  border: 2px solid var(--gris-marca, #E1E1DB);
  background: #FFFFFF;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;

  .mc-progressBar-progress {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 0;
    height: 28px;
    border-radius: 6px;
    background: linear-gradient(90deg, #418427 0%, #4E9D2E 47.53%, #5EBE38 85.93%);
    margin: auto;
    z-index: 1;
    border-radius: 4px;
    transition: width 3s ease-out;
    -webkit-transition: width 3s ease-out;
    -moz-transition: width 3s ease-out;
    -ms-transition: width 3s ease-out;
    -o-transition: width 3s ease-out;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $secondaryColor;
      border-radius: 4px;
      right: -4px;
      top: -2px;
      z-index: 1;
    }
  }

  .mc-progressBar-percent {
    z-index: 2;
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    color: $colorText;
    font-family: $ZonaPro-ExtraBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    &.white {
      color: #fff;
    }
    &.gray {
      color: #E1E1DB;
    }
  }

  .mc-progressBar-bubble {
    position: absolute;
    width: 38px;
    height: auto;
    bottom: 2px;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      position: relative;
      left: -19px;
    }
  }

  .mc-progressBar-title {
    position: absolute;
    left: 0px;
    top: -26px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.5;
  }

  .mc-progressBar-label {
    position: absolute;
    right: 0px;
    top: -25px;
    font-size: 13px;
  }

  &.has-title {
    margin-top: 25px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}