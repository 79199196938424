.Preheader {
  padding: 12px 16px;
  background: var(--azul-marca, #17326A);

  .content-preheader {
    @media (max-width: 768px) {
      padding-top: 20px;

      br {
        display: none;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0px;
      cursor: pointer;
      height: 24px;
      width: 24px;
      
      &:hover {
        opacity: 0.8;
        background-color: #FFFFFF;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }

    p {
      color: var(--Text-Inverted, #F0F7FF);
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      text-align: left;
    }
  }

  &.hide{
    display: none;
  }
}