.mc-button-container {
  display: block;

  @media (max-width: 992px) {
    width: 100%;
  }

  button {
    font-family: $ZonaPro-ExtraBold;
    display: inline-block;
    width: 100%;
    border: 0px;
    height: 60px;
    cursor: pointer;
    border-radius: 2px;
    outline: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: var(--blanco-semitico, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 20px;

    }

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }

  &__primary {
    button {
      border: 2px solid var(--cta-2, #418427);
      background: var(--cta, #5EBE38);

      &:hover {
        box-shadow: 0px 0px 12px 0px #5EBE38;
      }

      &.mc-button-container__status--inactive {
        background-color: $primaryColorDisable;
        border: none;
        cursor: no-drop;
        opacity: 0.7;
        border-radius: var(--s, 8px);
        border: 2px solid var(--gris-secundario, #B4B4B4);
        background: var(--gris-marca, #E1E1DB);
        color: var(--gris-secundario, #B4B4B4);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;

        &:hover {
          box-shadow: none
        }
      }

      &.btn-login {
        width: 100%;
        height: 48px;
        padding: 0px 15px;
        border-radius: 6px;
        border: 2px solid var(--gris-secundario, #B4B4B4);
        background: var(--gris-marca, #E1E1DB);

        color: var(--gris-secundario, #B4B4B4);
        text-align: center;
        font-kerning: none;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 15px;

        @media (max-width: 992px) {
          font-size: 18px;
          height: 55px;

        }

        &:hover {
          box-shadow: none;
        }
      }
    }

  }

  &__secondary {
    button {
      border-radius: 6px;
      border: 2px solid var(--cta-2, #418427);
      background-color: #fff;
      color: var(--cta-2, #418427);

      &:hover {
        box-shadow: 0px 0px 12px 0px #5EBE38;
      }

      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  &__cancel {
    button {
      font-family: $ZonaPro-Thin !important;
      color: var(--gris-secundario, #B4B4B4);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      border-radius: var(--s, 8px);
      border: 1px solid var(--gris-secundario, #B4B4B4);
      background: var(--Secundarios-Neutro-9, #FFF);

      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  &__auxiliar {
    button {
      border-radius: 6px;
      border: 2px solid var(--gris-secundario, #B4B4B4);
      background: var(--gris-marca, #E1E1DB);
      color: var(--negro-semitico, #000);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;

      @media (max-width: 768px) {
        font-size: 18px;

      }

      &:hover {
        box-shadow: 0px 0px 12px 0px rgba(11, 11, 11, 0.75);
      }

      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }
}