.SectionAllies {
  position: relative;
  z-index: 0;
  margin: 72px auto;
  padding: 0 50px;

  @media (max-width: 768px) {
   margin-bottom: 0px;
  }

  h2.title-allies {
    color: var(--negro-semitico, #000);
    text-align: left;
    font-family: $ZonaPro-ExtraBold;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    padding-left: 154px;    
    
    @media (max-width: 1200px) {
      padding-left: 80px;    
    }
    @media (max-width: 992px) {
      text-align: center !important;
      font-size: 24px !important;
      font-style: normal !important;
      line-height: 26px !important;
      padding-left: 0px;    
    }
  }

  @media (max-width: 768px) {
    padding: 0 0px;

  }

  .slick-slider {
    .slick-slide {
      transform: scale(0.6);
      opacity: 0;
      -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      transition: all .3s cubic-bezier(.77, 0, .175, 1);

      &.slick-active {
        transform: scale(1);
        opacity: 1;
      }
    }

    .slick-dots {
      li {
        width: 20px;
        height: 8px;
        margin: 0 4px;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        border-radius: 20px;
        background: var(--slider, rgba(225, 225, 219, 0.30));

        button {
          width: 100%;
          height: 100%;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          background: var(--cta-2, #418427);
        }
      }
    }

    .custom-next-arrow,
    .custom-prev-arrow {
      position: absolute;
      top: 105%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;

      @media (max-width: 992px) {
        top: 104%;

      }
      @media (max-width: 576px) {
        top: 105%;

      }
    }

    .custom-next-arrow {
      right: 15%;      
      @media (max-width: 992px) {
        right: 25%;
      }
      @media (max-width: 576px) {
        right: 5%;
      }
      @media (max-width: 390px) {
        right: 0%;
      }
    }

    .custom-prev-arrow {
      left: 15%;      
      @media (max-width: 992px) {
        left: 25%;
      }
      @media (max-width: 576px) {
        left: 5%;
      }
      @media (max-width: 390px) {
        left: 0%;
      }
    }
  }

  .slider-AlliancesGallery{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    @media (max-width: 390px) {
      max-width: 240px;      
    }
  }
}