.mc-app-container {
  position: relative;
  display: block;
  width: 1100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 24px;

  @media only screen and (min-width: 992px) {
    max-width: 90%;
    margin: 0 auto;
    z-index: 2;
  }

  &.custom-header {
    width: auto;
    max-width: 1280px;
    padding: 23px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 1100px) {
      padding: 23px 0px;
     
    }
    @media (max-width: 992px) {
      padding: 23px 24px;
     
    }
  }

  &.Preheader{
    padding: 0;
  }

  &.tab-premios {
    @media (min-width: 992px) {
      width: 1280px !important;      
    }
  }
}