.mc-slider-rotator {
  position: relative;
  margin-bottom: 50px;

  .slick-slider {
    .slick-slide {
      transform: scale(0.6);
      opacity: 0;
      -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      transition: all .3s cubic-bezier(.77, 0, .175, 1);

      &.slick-active {
        transform: scale(1);
        opacity: 1;
      }
    }

    .slick-dots {
      li {
        width: 20px;
        height: 8px;
        margin: 0 4px;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        border-radius: 20px;
        background: var(--slider, rgba(225, 225, 219, 0.30));

        button {
          width: 100%;
          height: 100%;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          background: var(--cta-2, #418427);
        }
      }
    }

    .custom-next-arrow,
    .custom-prev-arrow {
      position: absolute;
      top: 105%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;

      @media (max-width: 992px) {
        top: 104%;

      }
      @media (max-width: 576px) {
        top: 105%;

      }
    }

    .custom-next-arrow {
      right: 37%;      
      @media (max-width: 992px) {
        right: 25%;
      }
      @media (max-width: 576px) {
        right: 5%;
      }
      @media (max-width: 390px) {
        right: 0%;
      }
    }

    .custom-prev-arrow {
      left: 37%;      
      @media (max-width: 992px) {
        left: 25%;
      }
      @media (max-width: 576px) {
        left: 5%;
      }
      @media (max-width: 390px) {
        left: 0%;
      }
    }
  }


  .mc-slider-rotator__slide {
    position: relative;
  }
}